.day{
    width: calc(100% / 7);
    text-align: center;
    cursor: pointer;
    padding-top: 1.3rem;
  }
  .day :hover{
   border-radius: 10px;
   background: orange ;
  }
  
  .today{
    border-bottom: 2px solid royalblue;
  }
  
  .calender-wrapper{
    max-width: 400px;
      background-color: #203594;
      border-radius: 21px;
      padding: 23px;
      margin: auto;
      display: flex;
      justify-content: space-evenly;
      flex-direction: column;
  }

  .start-day{
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .end-day{
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  