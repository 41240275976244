:root {
    --primary: #203594;
    --light: #20359440;
    --error: #FF0000;
    --bg: rgba(196, 196, 196, 0.1);
    --border: rgba(0, 0, 0, 0.1);
}
.select {
    display: flex;
    padding: 5px;
    background: var(--bg);
    border: 1px solid var(--border);
    border-radius: 5px;
    position: relative;
    justify-content: space-between;
    cursor: pointer;
    align-items: center;
    width: 200px;
    background-color: #203594;
    color: #ffffff;
}

.select-clicked{
    border: 1px solid #203594;
}
.select__options {
    position: absolute;
    background: #ffffff;
    display: flex;
    flex: 1;
    margin-top: 5px;
    border-radius: 5px;
    border: 1px solid #dfdfdf;
    width: 100%;
    width: 210px;
    overflow: hidden;
    z-index: 20;
}


.select__options>ul {
    list-style: none;
    padding: 0;
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color:#FF914d;
}

.select__options>ul>li, .select>span {
    font-size: 1rem;
    font-weight: 800;
    color: #fff;
}

.select__options li {
    padding: 5px 5px;
    cursor: pointer;
}

.select__options li:hover {
    background-color: #203594;
    color: #ffffff;
}