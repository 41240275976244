.header__sticky {
    position: -webkit-sticky;
    /* Safari */
    position: sticky;
    top: 0;
    background-color: #ffffff;
    z-index: 1;
}

.logo__image {
    height: 70px;
}

.menu_icon{
    height: 21px;
    width: 21px;
}

.padding10 {
    padding: 10px 20px;
}

.header__right {
    display: flex;
    align-items: center;
}

.header__left {
    display: flex;
    flex: 1;
}

.header__wrapper {
    display: flex;
    justify-content: space-evenly;
}

.header__profile {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-size: 14px;
}

.profile__name {
    font-size: 12px;
    color: #203594;
    font-weight: bold;
}

.profile__role {
    font-size: 10px;
    color: #203594;
    opacity: 0.7;
}

.span-btn {
    cursor: pointer;
}

.profile__role {
    font-size: 10px;
    color: #203594;
    opacity: 0.7;
}
.container__topmenu {
    /* background-color: red; */
    display: flex;
    flex: 1;
    justify-content: flex-end;
}

.container__nav {
    background-color: #203594;
    padding: 10px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    display: flex;
    justify-content: space-evenly;
    flex: 0.90;
}
.nav-icon {
    font-size: 1.5rem;
    font-style: normal;
    font-weight: normal;
    margin-bottom: 2px;
    filter: brightness(8) invert(0);
}

.edit-button{
    background:#FF914D;

    border-radius:10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 26px;
  }

  .table_edit_icon {
    height:16px;
    width:15px;
    transition:all 0.2s ease ;

}
.table_edit_icon:hover {
    height:20px;
    width:20px;
}

.m-5{
margin: 5px !important;
}

.m-10{
margin: 10px !important;
}
.m-15{
margin: 15px !important;
}
.m-20{
margin: 20px !important;
}

.m-b-5{
    margin-bottom: 5px !important;
}
.m-b-10{
    margin-bottom: 10px !important;
}
.m-b-15{
    margin-bottom: 15px !important;
}
.m-b-20{
    margin-bottom: 20px !important;
}

.m-t-5{
    margin-top: 5px !important;
}
.m-t-10{
    margin-top: 10px !important;
}
.m-t-15{
    margin-top: 15px !important;
}
.m-t-20{
    margin-top: 20px !important;
}

.m-r-5{
    margin-right: 5px !important;
}
.m-r-10{
    margin-right: 10px !important;
}
.m-r-15{
    margin-right: 15px !important;
}
.m-r-20{
    margin-right: 20px !important;
}






.container__nav {
    background-color: #203594;
    padding: 10px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    display: flex;
    justify-content: space-evenly;
    flex: 0.90;
}

.container__nav-full {
    background-color: #203594;
    padding: 10px;
    display: flex;
    justify-content: space-evenly;
    flex: 1;
}

.container__nav>ul, .container__nav-full>ul {
    list-style: none;
    color: white;
    display: flex;
    flex-direction: row;
    margin: 0px 20px;
    justify-content: space-evenly;
    margin: 0;
    padding: 0;
    flex: 1;
}

.container__nav li, .container__nav-full li {
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    padding: 5px 15px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: white;
    margin-left: 5px;
}

.container__nav li:hover, .container__nav-full li:hover .nav-icon {
    background-color: #ffffff;
    color: #203594;
    border-radius: 10px;
}
.container__nav li:hover .nav-icon  {
    background-color: #ffffff;
    color: #203594;
    border-radius: 10px;
    filter: brightness(1) invert(0) !important;
}

.nav-icon-active {
    color: #203594 !important;
    border-radius: 10px;
    filter: brightness(1) invert(0) !important;
}



.container__nav li.active, .container__nav-full li.active {
    background-color: #ffffff;
    color: #203594;
    border-radius: 10px;
}

.container__nav li>img, .container__nav-full li>img {
    height: 2.4rem;
}

.view__wrapper {
    display: flex;
    flex: 1;
}

.container__sidemenu-wrapper {
    display: flex;
    width: 130px;
}

.container__sidemenu, .container__sidemenu-accent {

    display: flex;
    height: 100%;
    align-items: center;
    flex: 0.2 1;
    position: fixed;
    z-index: 1;
    top: 8%;
    overflow-x: hidden;
}


.container__sidemenu li>img, .container__sidemenu-accent li>img, .container__sidemenu-wrapper li>img {
    height: 2.4rem;
}

.container__sidemenu>ul, .container__sidemenu-wrapper>ul {
    padding: 10px 0px;
    background-color: #203594;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    list-style: none;
    align-self: center;
    width: 130px;
}

.container__sidemenu li, .container__sidemenu-wrapper li {
    padding: 15px 5px !important;
    font-size: 1rem !important;
    color: white !important;
    text-align: center !important;
    cursor: pointer !important;
    margin: 5px 0px !important;
}

.container__sidemenu li.active, .container__sidemenu-wrapper li.active {
    background-color: #FF914D;
}

.container__sidemenu li:hover, .container__sidemenu-wrapper li:hover {
    background-color: #FF914D;
}

.center-row{
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    text-align: center !important;
}

.sub_wrapper {
    display: flex;
    position: fixed;
    height: -webkit-fill-available;
    align-items: center;
}

.filter_icon_wrapper{
    height: 44x;
    width: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.filter_icon {
    height:36px;
    width:36px;
    transition:all 0.2s ease ;

}
.filter_icon:hover {
    height:44px;
    width:44px;
}


/* sub */

.sub_wrapper li>img {
    height: 2.4rem;
}

.sub_wrapper>ul {
    padding: 10px 0px;
    background-color: #203594;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    list-style: none;
    /* align-self: unset; */
    width: 130px;
    /* position: sticky; */
    height: fit-content;
    margin-top: -150px;
}

.sub_wrapper li {
    padding: 15px 5px;
    font-size: 1rem !important;
    color: white;
    text-align: center;
    cursor: pointer;
    margin: 5px 0px;
}

.sub_wrapper li.active {
    background-color: #FF914D;
}

.sub_wrapper li:hover {
    background-color: #FF914D;
}
/* accent */
.sub_wrapper-accent{
    display: flex;
    /* background-color: red; */
    position: fixed;
    height: -webkit-fill-available;
    /* align-items: flex-start; */
    align-items: center;
}
.sub_wrapper-accent li>img {
    height: 2.4rem;
}

.sub_wrapper-accent>ul {
    padding: 10px 0px;
    background-color: #FF914D;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    list-style: none;
    /* align-self: unset; */
    width: 130px;
    /* position: sticky; */
    height: fit-content;
    margin-top: -120px;
}

.sub_wrapper-accent li {
    padding: 15px 5px;
    font-size: 1.2rem;
    color: white;
    text-align: center;
    cursor: pointer;
    margin: 5px 0px;
}

.sub_wrapper-accent li.active {
    background-color: #FF914D;
}

.sub_wrapper-accent li:hover {
    background-color: #FF914D;
}
.container__content {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 10px;
    /* margin-left: 12%; */
    overflow-x: auto;
}
.padded-container {
    padding: 20px;
}

.table_heading{
    display: flex;
    justify-content: space-between;
}

.column_edit_icon{
    padding: 0px 5px;
    cursor: pointer;
}

.color_round_div{
    height: 15px;
    width: 15px;
    border-radius: 50%;
    border: 1px solid;
    margin: 0px 5px;
}

.error_value{
    color: red;
    text-align: center;
}


