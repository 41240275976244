.login_image{
    height: 100vh;
}

.login_wrapper{
    display: flex;
}

.login_form_wrapper{
    display: flex;
    flex-direction: column;
    width: 60%;
    padding: 30px;
    margin: 28vh 0vh;
}

.m-t-40{
    margin-top: 40px;
}

.m-t-30{
    margin-top: 30px;
}

.m-t-20{
    margin-top: 20px;
}

