@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  margin: 0;
}

body {
  margin: 0;
  font-family: Montserrat, sans-serif;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 12px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


a {
  text-decoration: none;
  cursor: pointer;
}

h1,h2,h3,h4,h5,h6{
  color: #203594;
}

/* table */

table {
  border-collapse: collapse;
  width: 100%;
  color: #203594;
}

tr {
  border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
  margin-top: 5px;
}

tr:hover {
  box-shadow: 0px 4px 20px 3px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
}
th,td {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 20px;
  margin: 5px 0px;
  font-size: 12px;
  border: none
}




/* width */

::-webkit-scrollbar {
  width: 10px;
}

/* Track */

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px dimgray;
  border-radius: 10px;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 10px;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: gray;
}

input, div, a, p, html {
  font-family: Montserrat, sans-serif;
}
